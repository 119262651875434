<template>
  <UsersForm
    @save="updateUser"
    :user="user"
    :type="'edit'"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    UsersForm: () => import('./UsersForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    user: {},
    askToLeave: true,
  }),
  methods: {
    updateUser() {
      const formData = new FormData()
      const userKeys = Object.keys(this.user)
      const userValues = Object.values(this.user)

      for (let i = 0; i < userKeys.length; i++) {
        if (userValues[i] !== null) {
          formData.append(userKeys[i], userValues[i])
        }
      }
      this.askToLeave = false
      this.$api
        .post(`people/${this.$route.params.id}`, formData)
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Usuário atualizado com sucesso',
            confirm: () => {
              this.$router.push({ name: 'users' })
            },
          })
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha ao tentar atualizar este usuário',
            caption: 'Verifique se os campos estão preenchidos corretamente.',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
    getCurrentUser() {
      this.$api
        .get(`people/${this.$route.params.id}`)
        .then((res) => {
          this.user = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  mounted() {
    this.getCurrentUser()
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
